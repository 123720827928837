/*=========================================================================================
File Name: router.js
Description: Routes for vue-router. Lazy loading is enabled.
Object Strucutre:
path => router path
name => router name
component(lazy loading) => component to load
meta : {
	rule => which user can have access (ACL)
	breadcrumb => Add breadcrumb to specific page
	pageTitle => Display title besides breadcrumb
}
----------------------------------------------------------------------------------------
Item Name: Vuesax Admin - VueJS Dashboard Admin Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
// import auth from "@/auth/authService";
import store from './store/store.js';

Vue.use(Router)

function requireAuth(to, from, next) {
	/*
		Determines where we should send the user.
	*/
	function proceed() {
		/*
			If the user is not empty, that means there's a user
			authenticated we allow them to continue. Otherwise, we
			send the user back to the home page.
		*/
		if (JSON.parse(localStorage.getItem('userDetails'))) {
			switch (to.meta.permission) {
				/*
					If the route that requires authentication is a user, then we continue.
					All users can access these routes
				*/
				/*
					If the route that requires authentication is a guest and the role
					the user has is 'guest' or 'admin', we allow
					access. Otherwise we redirect back to the home.
				*/
				case 'guest':
					if (JSON.parse(localStorage.getItem('userDetails')).role == 'guest' || JSON.parse(localStorage.getItem('userDetails')).role == 'admin') {
						next();
					} else {
						next('/');
					}
					break;
				/*
					If the route that requires authentication is a admin and the role
					the user has is 'admin', we allow
					access. Otherwise we redirect back to the home.
				*/
				case 'admin':
					if (JSON.parse(localStorage.getItem('userDetails')).role == 'admin') {
						next();
					} else {
						next('/');
					}
					break;
			}
		} else {
			// Clear user details
			store.dispatch('auth/clearAuthenticatedUser');
			// Redirect to login
			next('/pages/login');
		}
	}

	/*
		Auth Check AXIOS call
	*/
	router.app.$axiosSecure.get(`/auth-check`)
	.then((response) => {
		if (response) {
			// Proceed to route
			proceed();
		}
	})
	.catch(error  => {
		// If user is not logged in
		if (error.response && error.response.status == 401) {
			// Clear user details
			store.dispatch('auth/clearAuthenticatedUser');
			// Redirect to login
			next('/pages/login');
		}
	})
}


const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior () {
		return { x: 0, y: 0 }
	},
	routes: [
		
		{
			// =============================================================================
			// MAIN LAYOUT ROUTES
			// =============================================================================
			path: '',
			component: () => import('./layouts/main/Main.vue'),
			children: [
				// =============================================================================
				// Theme Routes
				// =============================================================================
				{
					path: '/',
					name: 'search',
					component: () => import('./views/Search.vue'),
					beforeEnter: requireAuth,
					meta: {
						pageTitle: 'Domain Search',
						permission: 'guest'
					}
				},
			],
		},
		// =============================================================================
		// FULL PAGE LAYOUTS
		// =============================================================================
		{
			path: '',
			component: () => import('@/layouts/full-page/FullPage.vue'),
			children: [
				// =============================================================================
				// PAGES
				// =============================================================================
				{
					path: '/callback',
					name: 'authCallback',
					component: () => import('@/views/Callback.vue'),
					meta: {
						permission: 'guest'
					}
				},
				{
					path: '/pages/login',
					name: 'pageLogin',
					component: () => import('@/views/pages/Login.vue'),
					meta: {
						permission: 'guest'
					}
				},
				{
					path: '/pages/error-404',
					name: 'pageError404',
					component: () => import('@/views/pages/Error404.vue'),
					meta: {
						permission: 'guest'
					}
				},
			]
		},
		// Redirect to 404 page, if no match found
		{
			path: '*',
			redirect: '/pages/error-404'
		}
	],
})

router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg');
	if (appLoading) {
		appLoading.style.display = "none";
	}
	// Remove body loading class
	const appBody = document.body;
	if (appBody) {
		appBody.classList.remove("body-loading");
	}
})

router.beforeEach((to, from, next) => {
	// Add body loading class
	const appBody = document.body;
	if (appBody) {
		appBody.classList.add("body-loading");
	}
	
	// firebase.auth().onAuthStateChanged(() => {
	
	//     // get firebase current user
	//     const firebaseCurrentUser = firebase.auth().currentUser;
	
	//     if (
	//         to.path === "/pages/login" ||
	//         to.path === "/pages/forgot-password" ||
	//         to.path === "/pages/error-404" ||
	//         to.path === "/pages/error-500" ||
	//         to.path === "/pages/register" ||
	//         to.path === "/callback" ||
	//         to.path === "/pages/comingsoon" ||
	//         (auth.isAuthenticated() || firebaseCurrentUser)
	//     ) {
	//         return next();
	//     }
	
	//     router.push({ path: '/pages/login', query: { to: to.path } })
	//     // Specify the current path as the customState parameter, meaning it
	//     // will be returned to the application after auth
	//     // auth.login({ target: to.path });
	
	// });
	
	// Custom login
	// if (
	// 	to.path === "/pages/login" ||
	// 	localStorage.getItem('userInfo')
	// 	) {
	// 		return next();
	// 	}
		
	// 	router.push({ path: '/pages/login', query: { to: to.path } })

	return next();
		
});
	
export default router
	