import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


// Google OAuth
import GAuth from 'vue-google-oauth2'
const gauthOption = {
	clientId: '125666245859-gaeep9mu5c8hji3a2gck38n971hk8nfn.apps.googleusercontent.com',
	scope: 'profile email',
	prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)


// API URL
Vue.prototype.$API = process.env.VUE_APP_API


// axios
import axios from 'axios'
Vue.prototype.$axios = axios;

const axiosInstance = axios.create({
	baseURL: `${process.env.VUE_APP_API}/api/v1`
});
axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
axiosInstance.defaults.headers.common['Accept'] = 'application/json';

Vue.prototype.$axiosSecure = axiosInstance;


// ACL
// import acl from './acl/acl'


// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuesax Admin Filters
import './filters/filters'


// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Moment
import moment from 'moment'
Vue.prototype.$moment = moment

// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

// Globally Reusable Mixin
Vue.mixin ({
	methods: {
		getQueryVariable: function(variable) {
			let query = window.location.search.substring(1);
			let vars = query.split("&");
			for (let i = 0; i < vars.length; i++) {
				let pair = vars[i].split("=");
				if(pair[0] == variable){return pair[1];}
			}
			return(false);
		},
		pushToArray: function (arr, obj, prop) {
			let index = arr.findIndex((e) => e[prop] === obj[prop]);
			// Check & Push to Array
			if (index === -1) {
				arr.push(obj);
			} else {
				arr.splice(index, 1, obj);
			}
		},
		removeFromArray: function (arr, obj, prop) {
			let index = arr.findIndex((e) => e[prop] === obj[prop]);
			// Check & Delete from Array
			if (index !== -1) {
				arr.splice(index, 1);
			}
		}
	}
})


new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
